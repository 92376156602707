





























import Vue from 'vue';
import SideTree from './component/SideTree.vue';
import TitleInfo from './component/TitleInfo.vue';
import authGuide from '../costQuotaLibrary/authGuide.vue';
import libraryList from './list.vue';
import { getQyLevelAll } from '@/api/materialMachineLibrary';
import { mapGetters } from 'vuex';
import { TreeType } from '@/interface/materialMachineLibrary';
export default Vue.extend({
  name: 'materialMachineLibrary',
  components: { SideTree, TitleInfo, authGuide, libraryList },
  props: {},
  data() {
    return {
      treeData: [] as TreeType[],
      selectedKeys: [],
      currentMaterialInfo: {},
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getTreeData();
  },
  methods: {
    getTreeData() {
      getQyLevelAll({
        resourceType: 1,
        agencyCode: this.userInfo?.agencyCode,
      }).then(({ status, result }) => {
        if (status === 200) {
          this.treeData = result;
        }
      });
    },
    selectType(
      selectedKeys: string[],
      {
        node,
      }: {
        node: { dataRef: Record<string, string | number>; expanded: boolean };
      }
    ) {
      const { levelCode, levelType } = node.dataRef;
      this.currentMaterialInfo = {
        levelCode,
        levelType,
      };
    },
    reset() {
      this.selectedKeys = [];
      this.currentMaterialInfo = {};
      this.getTreeData();
    },
  },
});
